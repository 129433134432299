import './App.css';
import {Routes, Route, BrowserRouter} from 'react-router-dom'
//import Story from './comp/story/story';
//import { useEffect } from 'react'
//import {useCookies} from 'react-cookie'
import Story2 from './comp/story/story2';
import Home from './comp/Home/Home'
import Books from './comp/books/books';

function App() {
  /*const [cookie, setCookie] = useCookies();
  useEffect(() => {
    if(cookie.language){
      if(cookie.language === 'en')
        Response.redirect()
    }
  },[])*/

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={'/'} element={<Home />} />
        <Route exact path={'/hem'} element={<Home />} />
        <Route exact path={'/hem/:language'} element={<Home />} />
        <Route path={'/barn-bocker'} element={<Books />} />
        <Route path={'/barn-bocker/:language'} element={<Books />} />
        <Route path={'/las-bok/'} element={<Story2 />} />        
        <Route path={'/las-bok/:id'} element={<Story2 />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
