import React,{useState} from 'react';
import {useNavigate,Link, useParams} from 'react-router-dom';
import './Navbar.css';
import data from './settings.json'
import logo from './logo192.png';
import icon from './menu-icon.svg';
import close from './menu-x.svg';
import swe from './swe.png'
import eng from './eng.png'


export default function Navbar(){
    const navigate = useNavigate();
    const [clicked,setClicked] = useState(false);

    let { language } = useParams();
    function isLanguage(value){
        if (language)
            if(language.toLowerCase() === value)
                return true;
        return false;
    }

    // Navbar Settings
    const redirect = (path) => {
        setClicked(false);
        navigate(path);
    }

    const handleLanguage = (nl) => {
        var path = window.location.pathname.toLowerCase();
        var cl = path.substring(path.length -3) === '/en' ? 'en' : 'sv';
        if(path === '/') path = '/hem';
        if(cl === 'en' && nl === 'sv')
            redirect(path.substring(0,path.length-3));
        if( cl === 'sv' && nl === 'en')
            redirect(path + "/en");

    }

    return (
        <div className='navbar wrapper'>
            <div className='navbar content'>
                <div className='navbar logo' onClick={() => redirect(isLanguage('en') ? data.links[data.linkdefault].en.to : data.links[data.linkdefault].sv.to)}>
                    <img src={logo} alt={data.imgseo.alt} title={data.imgseo.title} /> 
                    <div className='navbar title'>{isLanguage('en') ? data.title.en : data.title.sv }</div>
                </div>

                <div className='navbar button' alt={data.imgseo.alt} title={data.imgseo.title} onClick={() => setClicked(a => !a)}>
                    <img src={clicked ? close : icon} alt={data.imgseo.alt} title={data.imgseo.title} />
                </div>
                

                <ul className={clicked ? 'navbar links active' : 'navbar links'}>
                    {data.links.map((a, i) => (
                        <li key={i} onClick={() => redirect(isLanguage('en') ? a.en.to : a.sv.to)}>
                            <Link to={isLanguage('en') ? a.en.to : a.sv.to}>{isLanguage('en') ? a.en.name : a.sv.name}</Link>
                        </li>
                    ))}
                    <li className='navbar language'>
                        <div>
                            <img src={swe} alt='svenska' title='sv' onClick={() => handleLanguage('sv')} /> 
                            <img src={eng} alt='english' title='en' onClick={() => handleLanguage('en')} />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

