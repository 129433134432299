import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Nav/Navbar";
import Footer from "../Nav/Footer";
import data from "./books.json";
import './books.css'
import iListen from './icon_listen.png'
import iRead from './icon_read.png'

import {useCallback, useState} from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function Books(){
    let navigate = useNavigate();
    let {language} = useParams()
    const isLanguage = (l) => {
        if(language)
            if(language.toLowerCase() === l)
                return true;
        return false;
    }

    const readBook = (listen) => {
        navigate('/las-bok/1');
    }

    return (
        <div className="page full vertical">
            <div className="grow">
                
                <Navbar />

                <div className="page content">
                    <h1 className="h1 center"> {isLanguage('en') ? 'Children Books' : 'Barnböcker' } </h1>
                    
                    <div className="books grid">
                    {data.map((item, ix) => 
                        <div className="books book" key={ix}>
                            <img src={isLanguage('en') ? item.cover.en : item.cover.sv} alt='children books' title='barnböcker' />
                            <div className="books info">
                                <span className="title"> {isLanguage('en') ? 'Title: ' + item.title.en : 'Titel: ' + item.title.sv} </span>
                                <span> { (isLanguage('en') ? 'Author: ' : 'Författare: ') + item.author} </span>
                                <span> { (isLanguage('en') ? 'Pages: ' : 'Sidor: ') + item.pages} </span>
                                <span> { (isLanguage('en') ? 'Ages: ' : 'Ålder: ') + item.age[0]+'-'+item.age[1]} </span>
                                <span className="tags"> {isLanguage('en') ? item.tags.en.join(', ') : item.tags.sv.join(', ')} </span>
                                <img src={iRead} alt='Läs'  title={isLanguage('en')? 'Read': 'Läs'} onClick={() => readBook(item.id, false)} /> 
                                {((isLanguage('en') && item.voice.en) || (!isLanguage('en') && item.voice.sv)) && 
                                <img src={iListen} alt='lyssna' title={isLanguage('en')? 'Listen': 'Lyssna'} onClick={() => readBook(item.id, true)} />
                                }
                            </div>
                        </div>
                    )}
                    </div>

                </div>
 
            </div> {/* Grow div */}
            
            <Footer />
        
        </div>
    );
}