import {useRef, useState,useEffect, useLayoutEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import useRes from './useResolution'
import './story.css'
import { isMobile } from 'react-device-detect'

export default function Story2(){
    // handling image load and resize
    const [resolution] = useRes(); // Used to capture the resized page.width/height
    const [scale,setScale] = useState(0); // Used to calculcate how to fit image
    const [fitHeight,setFitHeight] = useState(true); // Change view to fit image by Height or Width
    const [isLoaded,setLoaded] = useState(false); // Used to determine if the image is loaded
    // handling swipe
    const [swipeStart, setSwipeStart] = useState(null);
    const [swipeEnd, setSwipeEnd] = useState(null);
    const imgRef = useRef();
    const navigate = useNavigate();


    useEffect(() => { document.body.style.overflow = 'hidden'; },[]);
    useLayoutEffect(() => { handleFit();});

    // Changing variable that handles scaling over Landscape OR Portraite
    const handleFit = () => {
        var width = resolution.height*scale;
        if(width > 0)
            setFitHeight(resolution.width > width);
    }

    /* APPLIKATION LOGIC */
    const handleSwipeStart = (x) => {
        setSwipeEnd(null);
        setSwipeStart(x);
    }
    const handleSwipeMove = (x) => {
        setSwipeEnd(x);
        const xd = Math.ceil(x-swipeStart);
        imgRef.current.style.left=`${xd}px`;
    }
    const evalSwipe = () => {
        if(Math.abs(swipeEnd-swipeStart) > 130)
        {
            // Swipe
            if(swipeEnd>swipeStart)
            {
                imgRef.current.style.left = '200%';
                imgRef.current.style.transition='0.5s'
                imgRef.current.style.opacity=0;
                setTimeout(() => { return  navigate('/hem')}, 250);
            }
            else
            {
                imgRef.current.style.left = '-200%';
                imgRef.current.style.transition='0.5s'
                imgRef.current.style.opacity=0;
                setTimeout(() => { return  navigate('/hem')}, 250);
            }
        }
        else
        {
            imgRef.current.style=null;
        }
        setSwipeEnd(null);
        setSwipeStart(null);
    }
   

    const test = () => {
        setTimeout(() => { return  navigate('/hem')}, 250);
    }
    const test2 = () => {
        navigate('/hem');
    }

    return (
        <div className={isMobile ? 'story wrapper mobile' : 'story wrapper desktop'} >
            <div className={`story container ${fitHeight ? 'height' : 'width'}`}>
                {/* image for fast loading */}
                <img src='/books/rymdresan_00_s.png' onLoad={(o) => setScale(o.target.naturalWidth/o.target.naturalHeight)} className={isLoaded ? 'hide' : null} />
                {/* Real image */}
                <img src='/books/rymdresan_00.png' 
                    className={swipeStart === null ? 'main-image' : 'main-image move'}
                    ref={imgRef}
                    onLoad={() => setLoaded(true)} 
                    onTouchStart={e => {handleSwipeStart(e.touches[0].clientX);}} 
                    onTouchMove={e => handleSwipeMove(e.touches[0].clientX)}
                    onTouchEnd={() => evalSwipe()}
                />

                <div className='story test float'>
                    <button onClick={() => test()}>TEST TIMER</button>
                    <button onClick={() => test2()}>TEST REDIRECT</button>
                </div>

            </div>

        </div>
    );
}