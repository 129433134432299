import {useEffect, useState, useLayoutEffect} from 'react'

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

const useResolution = () => {
    const [res, setRes] = useState({"height": window.innerHeight,"width": window.innerWidth})
     
      useEffect(() => {
        const debouncedHandleResize = debounce(
            function handleResize() { setRes({"height": window.innerHeight,"width": window.innerWidth})}
            ,500
        );
    
        window.addEventListener('resize', debouncedHandleResize)
            return _ => { window.removeEventListener('resize', debouncedHandleResize) }
      })

      useLayoutEffect(() => {
        const debouncedHandleResize = debounce(
          function handleResize() { setRes({"height": window.innerHeight,"width": window.innerWidth})}
          ,500
        );
  
        window.addEventListener('resize', debouncedHandleResize)
          return _ => { window.removeEventListener('resize', debouncedHandleResize) }
      })

      return [res];
};

export default useResolution;

export const useFitHeight = (w, h) => {
  let [resolution] = useResolution();

  var wScale = w/h;

  if(resolution.width > resolution.height*wScale)
    return true;
  else
    return false;
}

