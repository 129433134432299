//import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../Nav/Navbar'
import Footer from '../Nav/Footer'
import './Home.css'
import imgBg from '../_images/bg-circus12.jpg'
import imgSound from './icon-sound.png'
import imgAction from './icon-action.png'
import imgTablet from './icon-tablet.png'


export default function Home(){
    let { language } = useParams();


    function isLanguage(value){
        if (language)
            if(language.toLowerCase() === value)
                return true;
        return false;
    }
    
    function getHeader(){
        return (<div>
            <span className='h1 center'>{isLanguage('en') ? 'Welcome to' : 'Välkommen till'} <h1 className='h1 inline'>{isLanguage('en') ? 'The Children Books' : ' Mina Barnböcker'}</h1></span>
            <span className='h3 center'>{isLanguage('en') ? 'Interactive child books online' : 'Interaktiva barnböcker på nätet'}</span>
        </div>);
    }

    return (
        <div>
            <Navbar />
            <div className='home top' style={{backgroundImage:`url('${imgBg}')`}} />

            <div className='home center content'>
                {getHeader()}
                <br />
                {isLanguage('en') ? 'Here you find us authors that love to entertain children.' : 'Här hittar du oss författare som brinner för barnböcker.'}

                <div className='home icons'>
                    <div className='home icon'>
                        <img src={imgTablet} /><br />
                        {isLanguage('en') ? 'Read on several languages': 'Läs på olika språk'}
                    </div>
                    <div className='home icon'>
                        <img src={imgSound} /><br />
                        {isLanguage('en') ? 'Have the book read to you' : 'Få boken uppläst'}
                    </div>
                    <div className='home icon'>
                        <img src={imgAction} /><br />
                        {isLanguage('en') ? 'Play around with sounds' : 'Lek med ljudeffekter'}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}