import './Footer.css'
import data from './settings.json'
import icon from './logo192.png'
import { useParams, Link } from 'react-router-dom'

export default function Footer(){
    let {language} = useParams();

    const isLanguage = (l) => {
        if(language)
            if(language.toLowerCase() === l)
                return true;
        return false;
    }

    return (
        <div className='footer wrapper'>
            <div className='footer content'>
                {/* LOGO-INFO */}
                <div className='footer logo'>
                    <img src={icon} />
                    <div className='footer info'>
                        <div className='title'>{isLanguage('en') ? data.title.en : data.title.sv}</div>
                        <div className='contact'>{data.email}</div>
                    </div>
                    
                </div>

                {/* LINKS */}
                
                <div  className='footer links'>
                    {isLanguage('en') ? 'Links' : 'Länkar'}
                    <ul>
                    {
                        data.links.map((itm,ix) => 
                        <li key={ix}>
                            <Link to={isLanguage('en') ? itm.en.to : itm.sv.to}>
                                <span className='default'>
                                    {isLanguage('en') ? itm.en.name : itm.sv.name}
                                </span>
                            </Link>
                             <span className='divider'>/</span> 
                            <Link to={isLanguage('en') ? itm.sv.to : itm.en.to}>
                                <span className='small'>{isLanguage('en') ? itm.sv.name : itm.en.name}</span>
                            </Link>
                        </li>
                        )
                    }
                </ul>
                </div>
                
            </div>
        </div>
    );
}